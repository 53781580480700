import { RowFixed } from 'components/Row'
import useTheme from 'hooks/useTheme'
import React, { useRef } from 'react'
import { useActiveNetworkVersion } from 'state/application/hooks'
import styled from 'styled-components'
import { TYPE } from 'theme'

const Container = styled.div`
  position: relative;
  z-index: 40;
`

const Wrapper = styled.div`
  border-radius: 12px;
  background-color: ${({ theme }) => theme.bg1};
  padding: 6px 8px;
  margin-right: 12px;
`

const LogoWrapper = styled.img`
  width: 20px;
  height: 20px;
`

export default function NetworkDropdown() {
  const [activeNetwork] = useActiveNetworkVersion()
  const theme = useTheme()

  const node = useRef<HTMLDivElement>(null)

  return (
    <Container ref={node}>
      <Wrapper>
        <RowFixed>
          <LogoWrapper src={activeNetwork.imageURL} />
          <TYPE.main fontSize="14px" color={theme.white} ml="8px" mt="-2px" mr="2px" style={{ whiteSpace: 'nowrap' }}>
            {activeNetwork.name}
          </TYPE.main>
        </RowFixed>
      </Wrapper>
    </Container>
  )
}
